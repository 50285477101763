import { useEffect } from "react";

function App() {
  useEffect(() => {
    let a = document.createElement("a");
    a.setAttribute(
      "href",
      "https://instagram.com/proyectoxwine?igshid=NmNmNjAwNzg="
    );
    a.click();
  }, []);
  return <div />;
}

export default App;
